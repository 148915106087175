import App from "./App.js";

export default function () {
  const clipboard = new ClipboardJS(".link");
  clipboard.on('success', function (event) {
    const tt = bootstrap.Tooltip.getInstance(event.trigger.querySelector("i"));

    if (typeof tt.timeout === "undefined") {
      tt.show();
      tt.timeout = setTimeout(function () {
        tt.hide();
        delete tt.timeout;
      }, 3000);
    }
  });
  const dialog = document.getElementById("user-add-dialog");

  return {
    newUser() {
      App.open(dialog);
    },
    addUser() {
      App.ajax("/users/add", {
        data: dialog,
        pin: true,
        reload: true,
      }).catch(function () {

      });
    },
  };
};
