import App from "./App.js";

function fitText(selector, baselen, fontSize) {
  const text = modalBody.querySelector(selector);
  let n = 0;
  text.textContent.replace(/[ก-ะา฿-ๆ๏-๛]/g, () => n++);
  text.style.fontSize = n > baselen
    ? `${fontSize * (baselen / n)}em`
    : `${fontSize}em`;
}

const modal = document.getElementById("modal");
const modalBody = document.getElementById("modal-body");

export default function () {
  if (!modal) {
    return;
  }

  const searchForm = document.getElementById("search-form");
  const submitButton = document.getElementById("search-submit-button");
  const exportButton = document.getElementById("search-export-button");
  let exportButtonDisabled = exportButton.disabled;
  const resetButton = document.getElementById("search-reset-button");
  const statusPendingInput = document.getElementById("status-pending-input");
  const pendingFirstInput = document.getElementById("pending-first-input");
  const inputHandler = function () {
    submitButton.disabled = false;
    resetButton.disabled = false;

    if (!exportButtonDisabled) {
      exportButton.disabled = true;
      exportButton.classList.add("d-none");
      resetButton.classList.remove("d-none");
    }

    searchForm.removeEventListener("input", inputHandler);
  };

  statusPendingInput.addEventListener("change", function () {
    pendingFirstInput.disabled = !statusPendingInput.checked;
  });

  searchForm.addEventListener("reset", function () {
    submitButton.disabled = true;

    if (!exportButtonDisabled) {
      exportButton.disabled = false;
      exportButton.classList.remove("d-none");
      resetButton.classList.add("d-none");
    }

    searchForm.addEventListener("input", inputHandler);
  });
  searchForm.addEventListener("input", inputHandler);

  const entry = {};

  return {
    acquireEntries: function (data) {
      App.ajax(`/entries/acquire`, {
        data: {
          amount: data.amount,
        },
        reload: true,
      }).catch(function (error) {
        console.log(error);
      });
    },
    exportEntries: function (data) {
      const form = searchForm.cloneNode(true);

      form.style.display = "none";
      document.body.appendChild(form);
      form.action = '/entries/csv';
      form.submit();
      document.body.removeChild(form);
      exportButtonDisabled = true;
      exportButton.classList.add("d-none");
      resetButton.classList.remove("d-none");
      /*ajax(`/entries/csv`, {
        data: searchForm,
        reload: true,
      }).catch(function (error) {
        console.log(error);
      });*/
    },
    viewEntry: function (data) {
      Object.assign(entry, data);
      modalBody.innerHTML = "";
      document.getElementById("entry-accept-button").disabled = entry.status === "accepted";
      document.getElementById("entry-reject-button").disabled = entry.status === "rejected";
      document.getElementById("entry-pdf-button").href = `/entries/${entry.id}/pdf`;
      App.ajax(`/entries/${entry.id}/html`).then(function (data) {
        modalBody.innerHTML = data;
        App.open(modal);

        fitText(".doc-data-text-name-lg", 20, 8);
        fitText(".doc-data-text-name", 25, 3.5);
      }).catch(function (error) {

      });
    },
    removeEntry: function (data) {
      App.ajax(`/entries/${entry.id}/remove`, {
        pin: true,
        reload: true,
      }).catch(function (error) {
        console.log(error);
      });
    },
    acceptEntry: function () {
      App.ajax(`/entries/${entry.id}/accept`, {
        reload: true,
      }).catch(function (error) {
        console.log(error);
      });
    },
    rejectEntry: function () {
      App.ajax(`/entries/${entry.id}/reject`, {
        reload: true,
      }).catch(function (error) {
        console.log(error);
      });
    },
  };
};
