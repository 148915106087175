import { validateAll } from "./validation.js";

requestAnimationFrame(function () {
  const main = document.querySelector("main");

  if (main && main.dataset._scrollToTop === "true") {
    requestAnimationFrame(function () {
      document.documentElement.scrollIntoView();
    });
  }
});

const dialogs = [];
const dialogData = new Map();
const scrollbarWidth = (function () {
  const div = document.createElement("DIV");
  div.style.width = "100px";
  div.style.height = "100px";
  div.style.overflow = "scroll";
  div.style.position = "absolute";
  div.style.left = "-9999px";
  div.style.top = "-9999px";
  document.body.appendChild(div);
  const value = 100 - div.clientWidth;
  document.body.removeChild(div);
  return value;
})();

function updateBodyPadding() {
  if (!dialogs.length || document.documentElement.scrollHeight === document.documentElement.clientHeight) {
    document.body.style.overflow = "";
    document.body.style.paddingRight = "";
  } else {
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }
}

window.addEventListener("resize", requestAnimationFrame.bind(window, updateBodyPadding));

const backdrop = document.createElement("DIV");
backdrop.className = "modal-backdrop";

let activeElement;

document.addEventListener("focusin", function (event) {
  activeElement = event.target;

  if (!dialogs.length) {
    return;
  }

  const dialog = dialogs[dialogs.length - 1];

  if (!document.hasFocus() || dialog.contains(document.activeElement)) {
    return;
  }

  for (const element of dialog.querySelectorAll("a[href], button, input, select, textarea, [tabindex]")) {
    if (element.disabled || element.classList.contains("disabled")) {
      continue;
    }

    setTimeout(element.focus.bind(element), 1);
    break;
  }
});

document.addEventListener("keydown", function (event) {
  if (event.code !== "Escape") {
    return;
  }

  if (!dialogs.length) {
    return;
  }

  const dialog = dialogs[dialogs.length - 1];
  const data = dialogData.get(dialog);

  if (data.dismissables) {
    close(dialog);
  }
});

{
  const HCODES = ["ArrowLeft", "ArrowRight"];
  const VCODES = ["ArrowUp", "ArrowDown"];

  for (const element of document.querySelectorAll(".keyboard-nav")) {
    const items = Array.from(element.querySelectorAll(".keyboard-nav-item"));
    const codes = element.classList.contains("keyboard-nav-horizontal")
      ? HCODES
      : VCODES;
    const keydown = function (event) {
      const direction = event.code === codes[0]
        ? -1
        : event.code === codes[1]
          ? 1
          : false;

      if (!direction) {
        return;
      }

      event.preventDefault();

      const c = items.indexOf(this);
      const n = items.length - 1;

      for (let i = c + direction; i !== c; i += direction) {
        if (i < 0) {
          i = n;
        } else if (i > n) {
          i = 0;
        }

        const item = items[i];

        if (item.disabled || item.classList.contains("disabled")) {
          continue;
        }

        item.focus();
        break;
      }
    };

    for (const item of items) {
      item.addEventListener("keydown", keydown);
    }
  }
}

function createTemplate(html) {
  let original;

  return function () {
    if (!original) {
      const template = document.createElement("TEMPLATE");

      template.innerHTML = html;
      html = null;
      original = template.content.removeChild(template.content.firstElementChild)
    }

    return original.cloneNode(true);
  };
}

function autoFocus(target) {
  const root = typeof target === "string"
    ? document.querySelector(target)
    : target || document;
  const element = root.querySelector(".auto-focus:not(:disabled):not(.disabled)");

  if (element) {
    requestAnimationFrame(function () {
      element.focus();
    });
  }
}

function disableInteractiveElements() {
  const root = dialogs.length
    ? dialogs[dialogs.length - 1]
    : document;
  const disabledElements = [];

  for (const element of root.querySelectorAll("a[href], button, input, select, textarea")) {
    if (element.tagName === "A") {
      if (!element.classList.contains("disabled")) {
        element.classList.add("disabled");
        disabledElements.push(element);
      }
    } else if (!element.disabled) {
      element.disabled = true;
      disabledElements.push(element);
    }
  }

  return function () {
    for (const element of disabledElements) {
      if (element.tagName === "A") {
        element.classList.remove("disabled");
      } else {
        element.disabled = false;
      }
    }
  };
};

function register(dialog, options) {
  const data = {};
  const dismissables = dialog.querySelectorAll('[data-_dismiss="dialog"]');

  if (activeElement) {
    activeElement.blur();
    data.activeElement = activeElement;
  }

  if (dismissables.length) {
    const clickHandler = function (event) {
      if (event.currentTarget.matches("a, button") || event.currentTarget === event.target || !event.currentTarget.contains(event.target)) {
        close(dialog);

        if (options && typeof options.dismiss === "function") {
          options.dismiss();
        }
      }
    };

    for (const element of dismissables) {
      element.addEventListener("click", clickHandler);

      if ("disabled" in element) {
        element.disabled = false;
      }
    }

    data.dismissables = dismissables;
    data.clickHandler = clickHandler;
  }

  if (dialog instanceof HTMLFormElement) {
    const submits = dialog.querySelectorAll('[type="submit"]');
    const inputHandler = function (event) {
      const valid = validateAll(dialog.elements);
      const disabled = event
        ? !valid
        : true;

      for (const element of submits) {
        element.disabled = disabled;
      }
    };
    const submitHandler = function (event) {
      event.preventDefault();

      if (options && typeof options.submit === "function") {
        options.submit();
      }
    };

    dialog.addEventListener("input", inputHandler);
    dialog.addEventListener("submit", submitHandler);
    dialog.reset();
    inputHandler();
    data.submits = submits;
    data.inputHandler = inputHandler;
    data.submitHandler = submitHandler;
  }

  dialogData.set(dialog, data);
}

function unregister(dialog) {
  const data = dialogData.get(dialog);

  dialogData.delete(dialog);

  if (data.dismissables) {
    for (const element of data.dismissables) {
      element.removeEventListener("click", data.clickHandler);
    }
  }

  if (data.submits) {
    dialog.removeEventListener("input", data.inputHandler);
    dialog.removeEventListener("submit", data.submitHandler);
  }

  return data.activeElement;
}

function open(target, options = {}) {
  const dialog = typeof target === "string"
    ? document.querySelector(target)
    : target;

  if (dialogs.includes(dialog)) {
    return;
  }

  register(dialog, options);

  const animatedElement = dialog.querySelector(".animate");

  if (animatedElement) {
    animatedElement.addEventListener("animationend", function handler(event) {
      this.removeEventListener("animationend", handler);
      this.classList.remove("animate-show");
      autoFocus(dialog);
    });
    animatedElement.classList.add("animate-show");
  }

  if (!backdrop.parentNode) {
    backdrop.classList.add("show");
  }

  dialog.classList.add("d-block");
  dialog.classList.remove("d-none");
  document.body.appendChild(dialog);
  document.body.insertBefore(backdrop, dialog);

  if (dialogs.includes(dialog)) {
    dialogs.splice(dialogs.indexOf(dialog), 1);
  }

  dialogs.push(dialog);
  updateBodyPadding();

  if (!animatedElement) {
    autoFocus(dialog);
  }
}

function close(target) {
  const dialog = typeof target === "string"
    ? document.querySelector(target)
    : target || dialogs[dialogs.length - 1];

  if (!dialogs.includes(dialog)) {
    return;
  }

  dialogs.splice(dialogs.indexOf(dialog), 1);

  const animatedElement = dialog.querySelector(".animate");
  const enableInteractiveElements = animatedElement
    ? disableInteractiveElements()
    : null;
  const previousActiveElement = unregister(dialog);
  const animationendHandler = function (event) {
    if (event) {
      this.removeEventListener("animationend", animationendHandler);
      this.classList.remove("animate-hide");
      enableInteractiveElements();
    }

    dialog.classList.add("d-none");
    dialog.classList.remove("d-block");

    if (dialogs.length) {
      document.body.insertBefore(backdrop, dialogs[dialogs.length - 1]);
    } else {
      backdrop.classList.remove("show");
      backdrop.remove();
    }

    updateBodyPadding();

    if (previousActiveElement) {
      requestAnimationFrame(function () {
        previousActiveElement.focus();
      });
    }
  };

  if (animatedElement) {
    animatedElement.addEventListener("animationend", animationendHandler);
    animatedElement.classList.add("animate-hide");
  } else {
    animationendHandler();
  }
}

const alert = (function () {
  const template = createTemplate(
`\
<div class="modal">
  <div class="modal-dialog modal-dialog-centered p-3">
    <div class="modal-content animate">
      <div class="modal-header">
        <div class="modal-title fw-bold"></div>
        <button class="btn-close" type="button" data-_dismiss="dialog"></button>
      </div>
      <div class="modal-body py-5">

      </div>
      <div class="modal-footer flex-nowrap">
        <button class="d-block w-75 mx-auto btn btn-lg btn-primary auto-focus" type="button" data-_dismiss="dialog">
          <i class="bi-check-lg"></i>
          ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
`
  );
  let dialog;
  let headerElement;
  let bodyElement;

  return function (message, title, options) {
    if (!dialog) {
      dialog = template();
      headerElement = dialog.querySelector(".modal-header");
      bodyElement = dialog.querySelector(".modal-body");
    }

    if (title) {
      headerElement.firstElementChild.innerHTML = title;
      headerElement.classList.remove("d-none");
    } else {
      headerElement.classList.add("d-none");
    }

    bodyElement.innerHTML = message;
    open(dialog, options);
  };
})();

const openPinDialog = (function () {
  const pinDialogTemplate = createTemplate(`\
  <form autocomplete="off" id="pin-dialog" class="modal">
    <div class="modal-dialog modal-dialog-centered p-3">
      <div class="modal-content animate">
        <div class="modal-header">
          <div class="modal-title fw-bold">
            <i class="bi-grid-3x3-gap-fill"></i>
            ระบุ PIN เพื่อยืนยันการดำเนินการ
          </div>
          <button class="btn-close" type="button" data-_dismiss="dialog"></button>
        </div>
        <div class="modal-body p-5">
          <input
            autocomplete="off"
            name="pin"
            class="pin-input form-control mx-auto text-center no-badge auto-focus"
            type="text"
            inputmode="numeric"
            minlength="4"
            maxlength="8"
            data-_type="pin"
            data-_required="true"
          >
        </div>
        <div class="modal-footer flex-nowrap">
          <button class="d-block w-100 btn btn-lg btn-light me-3" type="button" data-_dismiss="dialog">
            <i class="bi-x-lg"></i>
            ยกเลิก
          </button>
          <button class="d-block w-100 btn btn-lg btn-primary" type="submit">
            <i class="bi-check-lg"></i>
            ตกลง
          </button>
        </div>
      </div>
    </div>
  </form>
  `);
  let pinDialog;
  let pinInput;

  return function (action) {
    if (!pinDialog) {
      pinDialog = pinDialogTemplate();
      pinInput = pinDialog.querySelector("input");
      pinInput.addEventListener("beforeinput", function (event) {
        if (event.data && /[^0-9]/.test(event.data)) {
          event.preventDefault();
        }
      });
    }

    open(pinDialog, {
      submit() {
        action(pinInput.value);
      },
      dismiss() {
        action(false);
      },
    });
  };
})();

const entrypoints = {};
const actions = {};
const actionHandler = function (event) {
  if (event.cancelable) {
    event.preventDefault();
  }

  const action = event.currentTarget.dataset._action;

  if (typeof actions[action] === "function") {
    const dataset = event.currentTarget.dataset;
    const data = {};

    for (const name in dataset) {
      if (name[0] !== "_") {
        data[name] = dataset[name];
      }
    }

    actions[action](data, event.currentTarget);
  }
};

function addEntrypoint(name, entrypoint) {
  entrypoints[name] = entrypoint;
}

function addAction(name, handler) {
  actions[name] = handler;
}

function init() {
  for (const element of document.querySelectorAll("[data-_entrypoint]")) {
    const entrypoint = entrypoints[element.dataset._entrypoint];

    if (entrypoint) {
      Object.assign(actions, entrypoint());
    }
  }

  for (const element of document.querySelectorAll("[data-_action]")) {
    switch (element.tagName) {
      case "FORM":
        element.addEventListener("submit", actionHandler);
        break;
      default:
        element.addEventListener("click", actionHandler);
        break;
    }
  }

  autoFocus();
}

function ajax(url, options = {}) {
  let enableInteractiveElements;
  const finallyHander = function () {
    if (rejected) {
      if (enableInteractiveElements) {
        enableInteractiveElements();
      }

      return;
    }

    if (options.location) {
      if (options.replace) {
        window.location.replace(options.location);
      } else {
        window.location.assign(options.location);
      }
    } else if (options.reload) {
      window.location.reload();
    } else if (enableInteractiveElements) {
      enableInteractiveElements();
    }
  };
  let rejected = false;

  return new Promise(function (resolve, reject) {
    const action = function (pin) {
      if (pin === false) {
        rejected = true;
        resolve();
        return;
      }

      if (options.reload || options.location || options.disableInteractiveElements) {
        enableInteractiveElements = disableInteractiveElements();
      }

      const rejectWrapper = function () {
        rejected = true;
        reject(...arguments);
      };
      const xhr = new XMLHttpRequest();

      xhr.addEventListener("abort", rejectWrapper);
      xhr.addEventListener("error", rejectWrapper);
      xhr.addEventListener("readystatechange", function () {
        if (xhr.readyState !== 4) {
          return;
        }

        const responseUrl = url[0] === "/"
          ? new URL(xhr.responseURL).pathname
          : xhr.responseURL;

        if (responseUrl !== url) {
          window.location.assign("/login");
          return;
        }

        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            if (xhr.getResponseHeader("Content-Type") === "application/json") {
              resolve(JSON.parse(xhr.responseText));
            } else {
              resolve(xhr.response);
            }
          } catch (error) {
            rejectWrapper(error);
          }

          return;
        }

        const error = xhr.getResponseHeader("Content-Type") === "application/json"
          ? JSON.parse(xhr.responseText)
          : null;
        const errorAction = function () {
          if (error) {
            const alertOptions = {
              dismiss() {
                if (typeof pin === "undefined") {
                  rejectWrapper(xhr.status);
                } else if (enableInteractiveElements) {
                  enableInteractiveElements();
                }
              },
            };

            if (typeof error === "string") {
              alert(
                `<div class="text-center">${error}</div>`,
                alertOptions,
              );
            } else {
              alert(
                `<div class="text-center">${error.message}</div>`,
                `<i class="bi-exclamation-triangle"></i> ${error.title}`,
                alertOptions,
              );
            }
          } else {
            rejectWrapper(xhr.status);
          }
        };

        if (typeof pin === "undefined") {
          errorAction();
        } else {
          setTimeout(errorAction, 3000);
        }
      });

      const data = options.data;

      if (!data && !pin) {
        xhr.open("GET", url);
        xhr.send();
        return;
      }

      const fd = new FormData();

      if (pin) {
        fd.append("pin", pin);
      }

      if (data instanceof HTMLFormElement) {
        const inputs = data.elements;

        for (let i = 0; i < inputs.length; i += 1) {
          const input = inputs[i];

          if (!input.name) {
            continue;
          }

          let value;

          switch (input.type) {
            case "checkbox":
            case "radio":
              if (!input.checked) {
                continue;
              }
            default:
              value = input.value;
              break;
          }

          fd.append(input.name, value);
        }
      } else {
        for (const name in data) {
          fd.append(name, data[name]);
        }
      }

      xhr.open("POST", url);
      xhr.send(fd);
    };

    if (options.pin) {
      openPinDialog(action);
    } else {
      action();
    }
  }).finally(finallyHander);
}

export default {
  addEntrypoint,
  addAction,
  ajax,
  init,
  open,
  close,
  alert,
  autoFocus,
};
