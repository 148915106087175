import { validateAll } from "./validation.js";
import App from "./App.js";

export default function () {
  const form = document.getElementById("customization-form");
  const submitButton = form.querySelector('button[type="submit"]');

  form.addEventListener("input", function () {
    submitButton.disabled = !validateAll(form.elements);
  });

  return {
    customize: function () {
      App.ajax('/customizations/save', {
        data: form,
        reload: true,
      }).catch(function () {

      });
    },
  };
};
