import App from "./App.js";

export default function () {
  const profileDialog = document.getElementById("user-profile-dialog");

  if (!profileDialog) {
    return {};
  }

  const passwordDialog = document.getElementById("user-password-dialog");
  const pinDialog = document.getElementById("user-pin-dialog");

  return {
    userProfile: function () {
      App.open(profileDialog);
    },
    userProfileUpdate: function () {
      App.ajax("/user/profile", {
        data: profileDialog,
        pin: true,
        reload: true,
      }).catch(function () {

      });
    },
    userPassword: function () {
      App.open(passwordDialog);
    },
    userPasswordUpdate: function () {
      App.ajax("/user/password", {
        data: passwordDialog,
        reload: true,
      }).catch(function () {

      });
    },
    userPin: function () {
      App.open(pinDialog);
    },
    userPinUpdate: function () {
      App.ajax("/user/pin", {
        data: pinDialog,
        pin: true,
        reload: true,
      }).catch(function () {

      });
    },
  };
};
