import App from "./modules/App.js";
import entrypoint_user from "./modules/user.js";
import entrypoint_register from "./modules/register.js";
import entrypoint_entries from "./modules/entries.js";
import entrypoint_users from "./modules/users.js";
import entrypoint_customizations from "./modules/customizations.js";

for (const element of document.querySelectorAll('[data-bs-toggle="tooltip"]')) {
  new bootstrap.Tooltip(element);
}

App.addAction("login", function (data, form) {
  App.ajax("/login", {
    data: form,
    location: "/entries/",
  }).catch(function (error) {

  });
});
App.addEntrypoint("user", entrypoint_user);
App.addEntrypoint("register", entrypoint_register);
App.addEntrypoint("entries", entrypoint_entries);
App.addEntrypoint("users", entrypoint_users);
App.addEntrypoint("customizations", entrypoint_customizations);
App.init();
