const DNS_LABEL_PATTERN = '(?![^.]{64})[0-9a-z]+(?:-+[0-9a-z]+)*';
const HOSTNAME_PATTERN = `${DNS_LABEL_PATTERN}(?:\\.${DNS_LABEL_PATTERN})*`;
const DOMAIN_PATTERN = `${DNS_LABEL_PATTERN}(?:\\.${DNS_LABEL_PATTERN})+`;
const EMAIL_USERNAME_PATTERN = '(?![^@]{65})[0-9a-z]+(?:(?=[.\\-_])(?:\.?[\\-0-9_a-z]+)+)?';
const HOSTNAME_REGEX = new RegExp(`^${HOSTNAME_PATTERN}$`);
const DOMAIN_REGEX = new RegExp(`^${DOMAIN_PATTERN}$`);
const EMAIL_USERNAME_REGEX = new RegExp(`^${EMAIL_USERNAME_PATTERN}$`);
const EMAIL_REGEX = new RegExp(`^${EMAIL_USERNAME_PATTERN}@${DOMAIN_PATTERN}$`);
const USERNAME_REGEX = /^[0-9A-Za-z]+(?:[\-._]?[0-9A-Za-z]+)*$/;
const PASSWORD_REGEX = /^(?=[^0-9]*[0-9])(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])[!-~]+$/;
const TEL_REGEX = /^(?:02\d{7}|0(?:3[2-9]|4[2-5]|5[3-6]|7[3-7])\d{6}|0[689]\d{8})$/;

const normalizeText = function (s) {
  return s.trim().replace(/\s+/g, " ");
};
const normalizeNumber = function (s) {
  return s.trim().replace(/[ \-]/g, "");
};
const hasUniqueChars = function (s, n) {
  const o = {};

  for (let i = 0; i < s.length; i += 1) {
    if (!o[s[i]]) {
      o[s[i]] = true;
      n -= 1;

      if (n < 1) {
        return true;
      }
    }
  }
};
const hasInvalidThaiText = function (value) {
  return /ั(?:[^ก-ฮ่-๋]|[ก-ฮ][ำี-]ู็-์|$)|[เ-ไ](?:[^ก-ฮ]|$)|[^ก-ฮ][ัิ-ฺๅ-็ํ]|[^ก-ฮา่-๋]ะ|[^ก-ฮ่-๋][าำ]|[^ก-ฮัิ-ู][่-๋]|[^ก-ฮิุ]์/.test(value);
};
const isLeapYear = function (year) {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
};
const checkDate = function (date) {
  [year, month, day] = date.split("-");

  if (!year || !month || !day) {
    return false;
  }

  switch (day) {
    case "31":
      switch (month) {
        case "01":
        case "03":
        case "05":
        case "07":
        case "08":
        case "10":
        case "12":
          return true;
      }

      return "month";
    case "30":
      if (month !== "02") {
        return true;
      }

      return "month";
    case "29":
      if (month === "02") {
        if (isLeapYear(year)) {
          return true;
        }

        return "year";
      }
    default:
      break;
  }

  return true;
};
const validateId = function (s) {
  let id = normalizeNumber(s);

  if (/^[1-9]\d{12}$/.test(id)) {
    let sum = 0;

    for (let i = 0; i < 12; i += 1) {
        sum += Number(id[i]) * (13 - i);
    }

    if ((11 - sum % 11) % 10 === Number(id[12])) {
      return id;
    }
  }

  return undefined;
};
const validateText = function (value) {
  const s = normalizeText(value);

  return !s.length
    ? ""
    : !hasInvalidThaiText(s)
      ? s
      : undefined;
};
const validateThaiText = function (value) {
  const s = normalizeText(value);

  return !s.length
    ? ""
    : /^(?:[ก-๛]+(?: [ก-๛]+)*)$/.test(s) && !hasInvalidThaiText(s)
      ? s
      : undefined;
};
const validateInitial = function (value) {
  const s = normalizeText(value);

  return !s.length
    ? ""
    : /^(?:[ก-๛]+(?:[ \.][ก-๛]+)*)\.?$/.test(s) && !hasInvalidThaiText(s)
      ? s
      : undefined;
};
const validateTel = function (value) {
  const s = normalizeNumber(value);

  return !s.length
    ? ""
    : TEL_REGEX.test(s)
      ? s
      : undefined;
};
const validateEmail = function (value) {
  const s = value.trim().toLowerCase();

  return !s.length
    ? ""
    : EMAIL_REGEX.test(s)
      ? s
      : undefined;
};
const validators = {
  number: function (value) {
    return !value.length
      ? ""
      : /^(?:0|[1-9]\d{0,9})$/.test(value)
        ? value
        : undefined;
  },
  id: validateId,
  email: validateEmail,
  tel: validateTel,
  thai: validateThaiText,
  initial: validateInitial,
  username: function (value) {
    return !value.length
      ? ""
      : USERNAME_REGEX.test(value)
        ? value
        : undefined;
  },
  password: function (value) {
    return !value.length
      ? ""
      : PASSWORD_REGEX.test(value)
        ? value
        : undefined;
  },
  pin: function (value) {
    return !value.length
      ? ""
      : /^\d+$/.test(value) && hasUniqueChars(value, 3)
        ? value
        : undefined;
  },
};
const validateAll = function (inputs) {
  let invalid = 0;
  const radios = {};

  for (let i = 0; i < inputs.length; i += 1) {
    const input = inputs[i];

    if (!input.name) {
      continue;
    }

    if (input.type === "radio") {
      radios[input.name] ??= false;

      if (input.checked) {
        radios[input.name] = true;
      }

      continue;
    }

    if (input.type === "checkbox") {
      invalid += input.dataset._required === "true" && !input.checked;
      continue;
    }

    const hasBadge = !input.classList.contains("no-badge");

    if (hasBadge) {
      input.classList.remove("is-valid");
      input.classList.remove("is-invalid");
    }

    const validator = validators[input.dataset._type];
    const value = validator
      ? validator(input.value)
      : validateText(input.value);

    if (value !== "") {
      if (typeof value === "undefined"
        || (input.minLength > -1 && value.length < input.minLength)
        || (input.maxLength > -1 && value.length > input.maxLength)
      ) {
        invalid += 1;

        if (!input.classList.contains("no-badge")) {
          input.classList.add("is-invalid");
        }
      } else {
        const m = input.type === "password"
          ? input.name.match(/^(.*_)2$/)
          : null;

        if (m && inputs[m[1] + "1"].value !== value) {
          invalid += 1;

          if (hasBadge) {
            input.classList.add("is-invalid");
          }
        } else if (hasBadge) {
          input.classList.add("is-valid");
        }
      }
    } else if (input.dataset._required === "true") {
      invalid += 1;
    }
  }

  for (const name in radios) {
    invalid += !radios[name];
  }

  return invalid === 0;
};

export {
  validateId,
  hasInvalidThaiText,
  validateThaiText,
  validateInitial,
  validateTel,
  validateEmail,
  validateAll,
};
